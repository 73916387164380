<template>
  <div>
    <Breadcrumbs
      page_title="skillchart"
      :items="breadcrumbs"
      :excel_btn="true"
      @ShowAction="ShowAction"
    />

    <v-container fluid>
      <v-card v-if="showDataPage && isoaccess" class="rounded-lg">
        <v-card>
          <v-row class="ma-1">
            <v-col cols="12" md="7" lg="9" xl="9" sm="9" class="pa-5">
              <div
                class="table-wrapper"
                style="padding-bottom: 20px !important"
              >
                <tr>
                  <td class="section-column text-center" rowspan="3">
                    {{ $t("section") }}
                  </td>
                  <td>
                    <table
                      class="custom-main-table"
                      aria-describedby="main-table"
                    >
                      <tr>
                        <th
                          scope
                          v-for="(item, i) in topics"
                          :key="i"
                          class="main-topic-cell"
                        >
                          {{ item.mainTopic }}
                        </th>
                      </tr>

                      <tr>
                        <td
                          v-for="topic in topics"
                          :key="topic.mainTopicId"
                          class="sub-topic-cell"
                        >
                          <table
                            class="custom-sub-table"
                            aria-describedby="sub-table"
                          >
                            <th
                              v-for="(subitem, i) in WhereSubTopic(
                                topic.mainTopicId
                              )"
                              :key="i"
                              scope
                              class="sub-topic-cell-bg"
                            >
                              <div
                                class="subname-custom webkit-line-clamp-3-lines"
                                :style="[
                                  subitem.textSubLength > 50
                                    ? subitem.course_count == 1
                                      ? { width: '150px !important' }
                                      : { width: 'auto' }
                                    : subitem.textSubLength >= 30
                                    ? subitem.course_count == 1
                                      ? { width: '135px !important' }
                                      : { width: 'auto' }
                                    : { width: 'auto' },

                                  { height: subTopicHeight },
                                ]"
                              >
                                {{ subitem.subTopic }}
                              </div>
                            </th>
                            <tr>
                              <td
                                v-for="(subitem, i) in WhereSubTopic(
                                  topic.mainTopicId
                                )"
                                :key="i"
                                style="padding: 0px !important"
                              >
                                <table
                                  class="custom-course-table"
                                  aria-describedby="course-table"
                                >
                                  <th v-if="false" scope></th>
                                  <tr>
                                    <td
                                      v-for="(
                                        courseItem, i
                                      ) in WhereCoursesOfSubTopic(
                                        subitem.subTopicId
                                      )"
                                      :key="i"
                                      class="course-varticle"
                                    >
                                      <span
                                        :class="[
                                          courseItem.courseLength > 55
                                            ? 'course-container'
                                            : 'rotated-text',
                                        ]"
                                        >{{ courseItem.course }}</span
                                      >
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <!-- department data table info -->
                <div
                  v-if="hasDeptData"
                  class="mt-6"
                  style="overflow-x: hidden; width: max-content"
                >
                  <tr
                    v-for="dept_item in usersOfDeptList"
                    :key="dept_item.departmentId"
                  >
                    <td style="vertical-align: top">
                      <tr>
                        <th scope class="dept-large-column">
                          <span class="ml-1" style="font-size: 16px">{{
                            $t("departmentl")
                          }}</span>
                          <br />
                          <span
                            class="ml-1 white--text mb-3"
                            style="
                              font-size: 13px;
                              background-color: #ffc900;
                              border: 1px solid #ffc900;
                              padding: 2px;
                              border-radius: 6px;
                            "
                            >{{ dept_item.departmentName }}</span
                          >
                        </th>
                      </tr>
                      <tr>
                        <td colspan="1" class="dept-user-col-large">
                          <span class="ml-1" style="color: #4390de">{{
                            $t("usernamel")
                          }}</span>
                        </td>
                      </tr>
                      <tr v-for="(userItem, i) in dept_item.user" :key="i">
                        <td colspan="1" class="dept-username-col-large">
                          <p class="ml-2 userlengths" style="color: #424242">
                            {{
                              userItem.fullName.length > 28
                                ? userItem.fullName.substring(0, 27) + "..."
                                : userItem.fullName
                            }}
                          </p>
                          <div
                            v-if="userItem.showPercentage"
                            class="percent-user mr-2"
                            style="
                              background-color: #ffc900;
                              border: 1px solid #ffc900;
                              color: #ffffff;
                            "
                          >
                            {{ userItem.evaluationPercentage }}
                          </div>
                        </td>
                      </tr>
                    </td>
                    <td>
                      <table
                        class="custom-dept-table mb-5"
                        aria-describedby="dept-table"
                      >
                        <tr>
                          <th
                            v-for="dept in dept_item.Courses"
                            :key="dept.CourseIndex"
                            class="dept-col-cell"
                            scope
                          >
                            <v-checkbox
                              v-if="dept.flagCourseSelect"
                              class="mt-0 ml-1 sc-checkbox"
                              v-model="dept.flagCourseSelect"
                              @change="
                                toggleAllCheckboxes(
                                  dept_item.departmentId,
                                  dept.courseId,
                                  dept.flagCourseSelect
                                )
                              "
                              color="#707070"
                              disabled
                              hide-details
                            ></v-checkbox>
                          </th>
                        </tr>
                        <tr
                          style="
                            border-left: 1px solid #707070b3;
                            border-right: 1px solid #707070b3;
                          "
                        >
                          <td
                            v-for="(text, m) in dept_item.courseUI"
                            :key="m"
                            class="dept-userEmpty-col"
                            :style="[
                              text.textSubLength > 50
                                ? text.course_count == 1
                                  ? { 'min-width': '151.6px !important' }
                                  : { 'min-width': 'auto' }
                                : text.textSubLength >= 30
                                ? text.course_count == 1
                                  ? { 'min-width': '136px !important' }
                                  : { 'min-width': 'auto' }
                                : { 'min-width': 'auto' },
                            ]"
                          ></td>
                        </tr>

                        <tr
                          v-for="userItem in dept_item.user"
                          :key="userItem.userId"
                        >
                          <td
                            v-for="(iso_course, n) in userItem.isoCourseList"
                            :key="n"
                            class="dept-user-col"
                          >
                            <v-btn
                              v-if="iso_course.flagCourseSelect"
                              text
                              small
                              class="tableBtnSC"
                              style="
                                color: #ff6060;
                                background-color: #7070707f;
                              "
                              :style="[
                                iso_course.choice == 'N/A'
                                  ? {
                                      'background-color': '#7070707F',
                                      color: '#FF6060',
                                    }
                                  : iso_course.choice == 'M'
                                  ? {
                                      'background-color': '#A6CC39',
                                      color: '#424242',
                                    }
                                  : iso_course.choice == 'H'
                                  ? {
                                      'background-color': '#67AB30',
                                      color: '#424242',
                                    }
                                  : iso_course.choice == 'L'
                                  ? {
                                      'background-color': '#FFC900',
                                      color: '#424242',
                                    }
                                  : iso_course.choice == 'W'
                                  ? {
                                      'background-color': '#4390DE',
                                      color: '#ffffff',
                                    }
                                  : {},
                              ]"
                              @click="
                                clickEvaluate(
                                  userItem.userDataId,
                                  userItem.fullName,
                                  iso_course,
                                  dept_item
                                )
                              "
                              >{{ iso_course.choice }}</v-btn
                            >
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </div>
              </div>
              <v-row v-if="!hasDeptData" class="ma-1 mt-5">
                <v-col
                  cols="12"
                  md="8"
                  lg="11"
                  xl="11"
                  sm="11"
                  class="pt-0"
                  style="text-align: center"
                >
                  <v-btn
                    class="text-capitalize white--text ml-5"
                    style="font-size: 18px"
                    color="#A6CC39"
                    @click="$router.push('/trainingroadmap/settingposition')"
                    >{{ $t("adddepartmentone") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5" lg="3" xl="3" sm="3">
              <v-row class="ma-1">
                <v-col
                  cols="12"
                  style="display: flex; justify-content: center"
                  class="pl-0 pr-0"
                >
                  <v-card
                    style="
                      border-radius: 8px;
                      box-shadow: unset;
                      background: rgb(192, 192, 192, 0.22);
                      width: 255px;
                    "
                  >
                    <v-card-title class="pb-3" style="justify-content: center">
                      <span style="font-size: #424242; color: #424242">{{
                        $t("assessmentcriterial")
                      }}</span>
                    </v-card-title>
                    <v-divider
                      class="ml-5 mr-5"
                      style="border: 1px solid #cccccc; height: 1px"
                    ></v-divider>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="3" lg="3" xl="3" class="pl-2">
                          <span
                            class="criteria-title"
                            style="
                              background-color: #67ab30;
                              border: 1px solid #67ab30;
                            "
                            >{{ "H" }}</span
                          >
                        </v-col>
                        <v-col cols="12" md="9" lg="9" xl="9">
                          <span class="assessmentFont">{{
                            $t("highscore")
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3" lg="3" xl="3" class="pl-2">
                          <span
                            class="criteria-title"
                            style="
                              background-color: #a6cc39;
                              border: 1px solid #a6cc39;
                            "
                            >{{ "M" }}</span
                          >
                        </v-col>
                        <v-col cols="12" md="9" lg="9" xl="9">
                          <span class="assessmentFont">{{
                            $t("mediumscore")
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3" lg="3" xl="3" class="pl-2">
                          <span
                            class="criteria-title"
                            style="
                              background-color: #ffc900;
                              border: 1px solid #ffc900;
                            "
                            >{{ "L" }}</span
                          >
                        </v-col>
                        <v-col cols="12" md="9" lg="9" xl="9">
                          <span class="assessmentFont">{{
                            $t("lowscore")
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3" lg="3" xl="3" class="pr-0 pl-2">
                          <span
                            class="criteria-title"
                            style="
                              background-color: rgba(0, 105, 226, 0.7);
                              border: 1px solid rgba(0, 105, 226, 0.7);

                              color: #ffffff;
                            "
                            >{{ "W" }}</span
                          >
                        </v-col>
                        <v-col cols="12" md="9" lg="9" xl="9">
                          <span class="assessmentFont">{{
                            "Waiting for evaluation"
                          }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="3" lg="3" xl="3" class="pr-0 pl-2">
                          <span
                            class="criteria-title"
                            style="
                              background-color: #7070707f;
                              border: 1px solid #7070707f;
                              color: #ff6060;
                            "
                            >{{ "N/A" }}</span
                          >
                        </v-col>
                        <v-col cols="12" md="9" lg="9" xl="9">
                          <span class="assessmentFont">{{
                            $t("notavailable")
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="ma-1">
                <v-col
                  cols="12"
                  style="display: flex; justify-content: center"
                  class="pl-0 pr-0"
                >
                  <v-card
                    style="
                      border-radius: 8px;
                      box-shadow: unset;
                      background: rgb(192, 192, 192, 0.22);
                      width: 255px;
                    "
                  >
                    <v-card-title class="pb-3" style="justify-content: center">
                      <span style="font-size: 20px; color: #424242">{{
                        $t("statuspercentage")
                      }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="3" lg="3" xl="3" class="pr-0 pl-2">
                          <span
                            class="percent-title"
                            style="
                              background-color: #ffc900;
                              border: 1px solid #ffc900;
                              color: #ffffff;
                            "
                            >{{ "%" }}</span
                          >
                        </v-col>
                        <v-col cols="12" md="9" lg="9" xl="9">
                          <span class="assessmentFont">{{
                            $t("adminevaluation")
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
      <v-card
        v-else
        class="rounded-lg"
        height="100vh"
        style="background-color: #e0e0e07a"
      ></v-card>
    </v-container>

    <v-dialog v-model="evaluateDialog" width="1220" persistent scrollable>
      <v-card>
        <v-card-title style="font-size: 25px; height: 75px" class="pb-4">
          <v-icon style="color: #363636; font-size: 35px" class="mr-1"
            >mdi-list-box-outline</v-icon
          >
          {{ $t("evaluate") }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="evaluateDialog = false"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text style="height: 800px">
          <v-row>
            <v-col class="mt-2">
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-1 pr-0">
                  <label
                    for="departmentl"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("departmentl") }}:</label
                  >
                </v-col>
                <v-col class="pb-1 pl-0">
                  <label
                    for="e_deptName"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{ evaluateData.e_deptName }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-1 pr-0">
                  <label
                    for="maintopic"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("maintopic") }}:</label
                  >
                </v-col>
                <v-col class="pb-1 pl-0">
                  <label
                    for="e_mainTopic"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{ evaluateData.e_mainTopic }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-1 pr-0">
                  <label
                    for="subtopic"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("subtopic") }}:</label
                  >
                </v-col>
                <v-col class="pb-1 pl-0">
                  <label
                    for="e_subTopic"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{ evaluateData.e_subTopic }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-1 pr-0">
                  <label
                    for="course"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("course") }}:</label
                  >
                </v-col>
                <v-col class="pb-1 pl-0">
                  <label
                    for="e_course"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{ evaluateData.e_course }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-1 pr-0">
                  <label
                    for="courseperiod"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("courseperiod") }}:</label
                  >
                </v-col>
                <v-col class="pb-1 pl-0">
                  <label
                    for="e_coursePeriod"
                    style="font-size: 16px; color: #47484b; margin-top: 5px"
                    >{{ evaluateData.e_coursePeriod }}</label
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-1 pr-0">
                  <label
                    for="status"
                    style="
                      font-size: 16px;
                      color: #47484b;
                      font-weight: bold;
                      margin-top: 5px;
                    "
                    >{{ $t("status") }}:</label
                  >
                </v-col>
                <v-col class="pb-1 pl-0"
                  ><div
                    v-if="evaluateData.e_courseStatus === 'In Progress'"
                    style="background-color: #e58921"
                    class="statuscolor"
                  >
                    <p
                      style="
                        font-size: 16px;
                        padding-top: 10px;
                        color: #ffffff;
                        margin-top: 5px;
                        border-radius: 0;
                      "
                    >
                      {{ evaluateData.e_courseStatus }}
                    </p>
                  </div>
                  <div
                    v-if="evaluateData.e_courseStatus === 'Complete'"
                    style="background-color: #67ab30"
                    class="statuscolor"
                  >
                    <p
                      style="
                        font-size: 16px;
                        padding-top: 10px;
                        color: #ffffff;
                        margin-top: 5px;
                        border-radius: 0;
                      "
                    >
                      {{ evaluateData.e_courseStatus }}
                    </p>
                  </div>
                  <div
                    v-if="evaluateData.e_courseStatus === 'Not Started'"
                    style="background-color: #008aff"
                    class="statuscolor"
                  >
                    <p
                      style="
                        font-size: 16px;
                        padding-top: 10px;
                        color: #ffffff;
                        margin-top: 5px;
                        border-radius: 0;
                      "
                    >
                      {{ evaluateData.e_courseStatus }}
                    </p>
                  </div>
                  <div
                    v-if="evaluateData.e_courseStatus === 'Delay'"
                    style="background-color: #cd2211"
                    class="statuscolor"
                  >
                    <p
                      style="
                        font-size: 16px;
                        padding-top: 10px;
                        color: #ffffff;
                        margin-top: 5px;
                        border-radius: 0;
                      "
                    >
                      {{ evaluateData.e_courseStatus }}
                    </p>
                  </div>
                  <div
                    v-if="
                      evaluateData.e_courseStatus ===
                      'userhavenoaccesstocourseyet'
                    "
                  >
                    <p style="font-size: 16px; color: red">
                      {{ $t(evaluateData.e_courseStatus) }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col style="justify-content: center; display: flex">
              <v-card class="evaluate-chart-style mt-4">
                <v-card-title style="justify-content: center">
                  <span style="font-size: 16px; color: #424242">{{
                    evaluateData.e_fullName
                  }}</span>
                </v-card-title>
                <v-card-text
                  style="justify-content: center; display: flex"
                  class="mt-1"
                >
                  <div class="tab-container">
                    <div class="tab">
                      <span
                        class="tab-title"
                        style="
                          background-color: #67ab30;
                          border: 1px solid #67ab30;
                        "
                        >{{ "H" }}</span
                      >
                      <button
                        class="score-btn"
                        :class="{ active: activeTab === 1 }"
                        @click="changeTab(1)"
                      ></button>
                    </div>
                    <div class="tab-line"></div>
                    <div class="tab">
                      <span
                        class="tab-title"
                        style="
                          background-color: #a6cc39;
                          border: 1px solid #a6cc39;
                        "
                        >{{ "M" }}</span
                      >
                      <button
                        class="score-btn"
                        :class="{ active: activeTab === 2 }"
                        @click="changeTab(2)"
                      ></button>
                    </div>
                    <div class="tab-line"></div>
                    <div class="tab">
                      <span
                        class="tab-title"
                        style="
                          background-color: #ffc900;
                          border: 1px solid #ffc900;
                        "
                        >{{ "L" }}</span
                      >
                      <button
                        class="score-btn"
                        :class="{ active: activeTab === 3 }"
                        @click="changeTab(3)"
                      ></button>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Course Detail Table -->
          <v-row class="mt-5">
            <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-1 pr-0">
              <v-icon style="font-size: 35px" class="mr-1 mb-1"
                >mdi-alert-circle-outline</v-icon
              >
              <span
                style="
                  font-size: 20px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 15px;
                "
                >{{ $t("detail") }} :</span
              >
              &nbsp;
              <span
                style="
                  font-size: 20px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 15px;
                "
                >{{ $t("course") }}</span
              >
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col>
              <v-data-table
                :loading="detailloading"
                loading-text="Loading... Please wait"
                :headers="detailCoursesheader"
                :items="e_detailCourseList"
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-left">{{ item.videoTitle }}</td>
                    <td class="text-center">{{ item.firstViewDate }}</td>
                    <td class="text-center">{{ item.lastViewDate }}</td>
                    <td class="text-left pl-5">{{ item.time }}</td>
                    <td class="text-left pl-5">{{ item.viewTime }}</td>
                    <td class="text-center">
                      <v-chip
                        v-if="item.statusVideo == 'Finished'"
                        class="finished"
                        >{{ item.statusVideo }}</v-chip
                      >
                      <v-chip
                        v-if="item.statusVideo == 'Not finished'"
                        class="notfinish"
                        >{{ $t("NotFinished") }}</v-chip
                      >
                      <v-chip
                        v-if="item.statusVideo == 'Not started'"
                        class="notstarted"
                        >{{ $t("NotStarted") }}</v-chip
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <!-- Quiz Detail Table -->

          <!-- Quiz Pre-test Detail Table -->
          <v-row class="mt-5">
            <v-col cols="12" lg="4" xl="4" md="6" sm="6" class="pb-1 pr-0">
              <v-icon style="font-size: 35px" class="mr-1 mb-1"
                >mdi-alert-circle-outline</v-icon
              >
              <span
                style="
                  font-size: 20px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 15px;
                "
                >{{ $t("detail") }} :</span
              >
              &nbsp;
              <span
                style="
                  font-size: 20px;
                  color: #47484b;
                  font-weight: bold;
                  margin-top: 15px;
                "
                >{{ $t("quiz") }}</span
              >
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col>
              <v-data-table
                :loading="detailloading"
                loading-text="Loading... Please wait"
                :headers="detailQuizsheader"
                :items="e_detailQuizList"
                hide-default-footer
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td class="text-left">{{ item.quizName }}</td>
                    <td class="text-center">{{ item.lastQuizDate }}</td>
                    <td class="text-center">{{ item.numberOfQuiz }}</td>
                    <td class="text-center">{{ item.passingScore }}</td>
                    <td class="text-center">{{ item.highFullScore }}</td>
                    <td class="text-center">{{ item.quizType }}</td>
                    <td class="text-center">
                      <v-chip
                        v-if="item.statusQuiz == 'Finished'"
                        class="finished"
                        >{{ item.statusQuiz }}</v-chip
                      >
                      <v-chip
                        v-if="item.statusQuiz == 'Not finished'"
                        class="notfinish"
                        >{{ $t("NotFinished") }}</v-chip
                      >
                      <v-chip
                        v-if="item.statusQuiz == 'Not started'"
                        class="notstarted"
                        >{{ $t("NotStarted") }}</v-chip
                      >
                      <v-chip v-if="item.statusQuiz == '-'" class="noquiz">{{
                        "No Quiz"
                      }}</v-chip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <div v-if="this.quizstatus == null" class="noquiz">
                <p>{{ $t("noquizdata") }}</p>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12" lg="12" xl="12" md="12" sm="12" >
              <v-row class="ma-0 pa-0" no-gutters>
               
                <v-col cols="auto" class="d-flex align-center">
                  <div class="mr-1 mb-0 d-flex align-center">
                    <v-icon style="font-size: 35px" class="mt-3">mdi-alert-circle-outline</v-icon>
                    <span
                      style="
                        font-size: 20px;
                        color: #47484b;
                        font-weight: bold;
                        margin-left: 8px;
                        margin-top: 15px;
                      "
                    >{{ $t("sendemailnoti") }}</span>
                  </div>
                </v-col>
                
               
                <v-col  class="d-flex align-center mt-3 ml-2">
                  <div>
                    <v-radio-group v-model="evaluateData.e_emailLanguage">
                      <v-row class="ma-0 pa-0" no-gutters>
                        <v-col cols="auto" class="d-flex align-center">
                          <!-- Flex container for radio and image -->
                          <div style="display: flex; align-items: center;">
                            <v-radio value="thai" class="mr-2 mt-2 ml-0"></v-radio>
                            <v-img
                              src="@/assets/thailandflag.svg"
                              width="35"
                              height="22"
                              contain
                            ></v-img>
                          </div>
                        </v-col>

                        <v-col cols="auto" class="d-flex align-center ml-2">
                          <!-- Flex container for radio and image -->
                          <div style="display: flex; align-items: center;">
                            <v-radio value="eng" class="mr-2 mt-2"></v-radio>
                            <v-img
                              src="@/assets/ukflag.svg"
                              width="35"
                              height="22"
                              contain
                            ></v-img>
                          </div>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row rows="12" lg="3" xl="3" md="4" sm="6" class="pl-10 ">
            <v-col cols="4" lg="2" xl="2" md="2" sm="4" class="pl-10"
              ><v-checkbox
                class="bold-label"
                v-model="evaluateData.e_flagCourseSelect"
                :label="$t('course')"
                color="#A6CC39"
                hide-details
              ></v-checkbox
            ></v-col>
            <v-col cols="4" lg="2" xl="2" md="2" sm="4" class="pl-10"
              ><v-checkbox
                class="bold-label"
                v-model="evaluateData.e_preQuizEmailFlag"
                :label="$t('pretest')"
                color="#A6CC39"
                hide-details
              ></v-checkbox
            ></v-col>
            <v-col cols="4" lg="2" xl="2" md="2" sm="4" class="pl-10">
              <v-checkbox
                class="bold-label"
                v-model="evaluateData.e_postQuizEmailFlag"
                :label="$t('posttest')"
                color="#A6CC39"
                hide-details
              ></v-checkbox
            ></v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="mt-3 mb-5 mr-3">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            text
            outlined
            @click="(evaluateDialog = false), (evaluateData = {})"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize white--text btnfont-style"
            color="#79BE43"
            @click="SaveEvaluate()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="crudloading" v-if="isoaccess" persistent width="300">
      <v-card color="#4FB14E" dark>
        <v-card-text class="white--text">
          Loading Please Wait...
          <v-progress-linear
            indeterminate
            color="#ffffff"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="noDataDialog" width="420" persistent>
      <v-card style="border-radius: 16px">
        <v-card-title>
          <v-row justify="center" no-gutters>
            <v-col style="text-align: center" class="pt-2 pb-2">
              <span class="mt-2" style="font-size: 20px; color: #363636">{{
                $t("plzentertopicandselectcoursetocreateskillchart")
              }}</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row no-gutters>
            <v-col style="text-align: center">
              <v-btn
                color="#A6CC39"
                width="170"
                height="60"
                style="font-size: 16px; border-radius: 8px; box-shadow: unset"
                class="text-capitalize white--text mt-1 mb-1"
                @click="
                  $router.push('/trainingroadmap/settingsection'),
                    (noDataDialog = false)
                "
              >
                <div
                  style="
                    width: 156px;
                    height: 39px;
                    word-break: break-all !important;
                    white-space: normal;
                  "
                >
                  {{ $t("opensettingsectionmenu") }}
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import exceljs from "exceljs";
import { saveAs } from "file-saver";

export default {
  components: {},
  data: () => ({
    permissionDialog: false,
    permissionMessage: "",
    courseEmailFlag: false,
    preQuizEmailFlag: false,
    postQuizEmailFlag: false,
    arrangeResult: [],
    subTopicHeight: "auto",
    choices: {
      1: "H",
      2: "M",
      3: "L",
    },
    noDataDialog: false,
    checkSign: "\u2713",
    isoaccess: false,
    hasDeptData: false,
    crudloading: false,
    //loading: true,
    showDataPage: false,
    //evaluate data dialog
    evaluateData: {
      e_flagCourseSelect:"",
      e_postQuizEmailFlag: "",
      e_preQuizEmailFlag: "",
      e_emailLanguage: "thai",
      e_deptName: "",
      e_mainTopic: "",
      e_subTopic: "",
      e_courseStatus: "",
      e_course: "",
      e_coursePeriod: "",
    },
    e_detailCourseList: [],
    e_detailQuizList: [],
    quizstatus: "",
    activeTab: 0,
    detailloading: false,
    evaluateDialog: false,
    //...................
    breadcrumbs: [
      {
        sidebar_tilte: "trainingroadmap",
      },
      {
        text: "skillchart",
      },
    ],
    usersOfDeptList: [],
    topics: [],
    sub_topics: [],
    courses_list: [],
  }),

  computed: {
    detailCoursesheader() {
      return [
        {
          text: this.$t("title"),
          value: "videoTitle",
          align: "left",
          width: "190px",
        },
        {
          text: this.$t("firstviewdate"),
          value: "firstViewDate",
          align: "left",
          width: "180px",
        },
        {
          text: this.$t("lastviewdate"),
          value: "lastViewDate",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("time"),
          value: "time",
          align: "left",
          width: "150px",
        },
        {
          text: this.$t("ViewTime"),
          value: "viewTime",
          align: "left",
          width: "150px",
        },
        {
          text: this.$t("statusvideo"),
          value: "statusVideo",
          align: "center",
          width: "180px",
        },
      ];
    },
    detailQuizsheader() {
      return [
        {
          text: this.$t("quizname"),
          value: "quizName",
          align: "left",
          width: "150px",
        },
        {
          text: this.$t("lastquizDate"),
          value: "lastQuizDate",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("numOfquiz"),
          value: "numberOfQuiz",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("Passingscore"),
          value: "passingScore",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("highscorefullscore"),
          value: "highFullScore",
          align: "center",
          width: "220px",
        },
        {
          text: this.$t("quiztype"),
          value: "highFullScore",
          align: "center",
          width: "220px",
        },
        {
          text: this.$t("statusquiz"),
          align: "center",
          width: "180px",
        },
      ];
    },
    detailQuizsPretestheader() {
      return [
        {
          text: this.$t("quizname"),
          value: "quizName",
          align: "left",
          width: "150px",
        },
        {
          text: this.$t("lastquizDate"),
          value: "lastQuizDate",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("numOfquiz"),
          value: "numberOfQuiz",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("Passingscore"),
          value: "passingScore",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("highscorefullscore"),
          value: "highFullScore",
          align: "center",
          width: "220px",
        },
        {
          text: this.$t("statusquiz"),
          value: "statusQuiz",
          align: "center",
          width: "180px",
        },
      ];
    },
    detailQuizsPosttestheader() {
      return [
        {
          text: this.$t("quizname"),
          value: "quizName",
          align: "left",
          width: "150px",
        },
        {
          text: this.$t("lastquizDate"),
          value: "lastQuizDate",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("numOfquiz"),
          value: "numberOfQuiz",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("Passingscore"),
          value: "passingScore",
          align: "center",
          width: "180px",
        },
        {
          text: this.$t("highscorefullscore"),
          value: "highFullScore",
          align: "center",
          width: "220px",
        },
        {
          text: this.$t("statusquiz"),
          value: "statusQuiz",
          align: "center",
          width: "180px",
        },
      ];
    },
  },

  mounted() {
    const accessqr = localStorage.getItem("isoaccess");
    this.isoaccess = JSON.parse(accessqr);

    this.getISOAssignment();
  },

  methods: {
    arrangeCourseList() {
      for (let t_ele of this.topics) {
        let subTemp = this.WhereSubTopic(t_ele.mainTopicId);
        for (let s_ele of subTemp) {
          let courseTemp = this.WhereCoursesOfSubTopic(s_ele.subTopicId);
          for (let c_ele of courseTemp) {
            const ans = {
              assignmentId: c_ele.assignmentId,
              idOfCourse: c_ele.idOfCourse,
              courseId: c_ele.courseId,
              course: c_ele.course,
              flagCourseSelect: c_ele.flagCourseSelect,
              course_count: s_ele.course_count,
              textSubLength: s_ele.textSubLength,
            };
            this.arrangeResult.push(ans);
          }
        }
      }
    },

    ShowAction(v) {
      if (v == "Export") {
        this.ExportExcel();
      }
    },

    async ExportExcel() {
      let headers = [];
      let topic_t = [];
      for (let tt in this.topics) {
        const mainTopicId = this.topics[tt].mainTopicId;
        const temp_t = this.sub_topics.filter(
          (n) => n.mainTopicId === mainTopicId
        );
        let c_count = temp_t.reduce((sum, item) => sum + item.course_count, 0);
        const newItem = {
          ...this.topics[tt],
          subtopic_count: temp_t.reduce(
            (sum, item) => sum + item.course_count,
            0
          ),
        };
        topic_t.push(newItem);
        headers.push(...Array(c_count).fill(this.topics[tt].mainTopic));
      }
      this.topics = topic_t;

      let subHeaders = [];
      this.sub_topics.sort((a, b) => a.subTopicId - b.subTopicId);
      for (let sub of this.sub_topics) {
        subHeaders.push(...Array(sub.course_count).fill(sub.subTopic));
      }

      this.courses_list.sort((e, f) => e.courseId - f.courseId);
      let courses = [];
      courses = this.courses_list.map((g) => g.course);

      //.....end of preparing data

      headers.unshift("Section");
      subHeaders.unshift("");
      courses.unshift("");

      const fileName = `${"SkillChart"}.xlsx`;
      const data = [];

      data.push(headers);
      data.push(subHeaders);
      data.push(courses);

      //department list data push to excel data
      if (this.usersOfDeptList.length != 0) {
        this.usersOfDeptList.forEach((u) => {
          let departmentNameRow = [];
          let userNameEmptyRow = [];
          userNameEmptyRow.push("Username");
          departmentNameRow.push(u.departmentName);

          u.Courses.forEach((f) => {
            if (f.flagCourseSelect) {
              departmentNameRow.push(this.checkSign);
            } else {
              departmentNameRow.push("");
            }
            userNameEmptyRow.push("");
          });
          data.push(departmentNameRow);
          data.push(userNameEmptyRow);

          if (u.user) {
            u.user.forEach((n) => {
              let user_temp = [];
              user_temp.push(n.fullName);

              if (n.isoCourseList.length != 0) {
                n.isoCourseList.forEach((k) => {
                  if (k.choice == null || k.choice == "") {
                    user_temp.push("N/A");
                  } else {
                    user_temp.push(k.choice);
                  }
                });
              }

              data.push(user_temp);
            });
          }
        });
      }

      // Excel sheet style start.......................
      let workbook2 = new exceljs.Workbook();
      workbook2.addWorksheet("Skill Chart Report", {
        views: [{ showGridLines: true }],
      });
      let worksheet2 = workbook2.getWorksheet(1);

      data.forEach((element) => {
        worksheet2.addRow(element);
      });

      const startRow = 1;
      const endRow = 3;
      worksheet2.mergeCells(`A${startRow}:A${endRow}`);

      //color fills for hearder column
      worksheet2.getRow(1).eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: number != 1 ? { argb: "DAEEF3" } : { argb: "FFFFFF" },
          bgColor: number != 1 ? { argb: "DAEEF3" } : { argb: "FFFFFF" },
        };
        cell.font = {
          name: "Calibri",
          size: number != 1 ? 11 : 16,
          color: { argb: "424242" },
          bold: true,
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

      //color fills for sub-hearder column
      worksheet2.getRow(2).eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: number != 1 ? { argb: "ACE1AF" } : { argb: "FFFFFF" },
          bgColor: number != 1 ? { argb: "ACE1AF" } : { argb: "FFFFFF" },
        };
        cell.font = {
          name: "Calibri",
          size: number != 1 ? 11 : 16,
          color: { argb: "424242" },
          bold: true,
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });

      //color fills for courses column
      worksheet2.getRow(3).eachCell((cell, number) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: number != 1 ? { argb: "FFFFFFF" } : { argb: "FFFFFF" },
          bgColor: number != 1 ? { argb: "FFFFFFF" } : { argb: "FFFFFF" },
        };
        cell.font = {
          name: "Calibri",
          size: number != 1 ? 11 : 16,
          color: number != 1 ? { argb: "67AB30" } : { argb: "424242" },
          bold: true,
        };
        cell.alignment = { vertical: "middle", horizontal: "center" };
      });
      //........................................

      //looping through sheet rows to set color for department and username row
      worksheet2.eachRow((row, rowNumber) => {
        const rowData = row.values; // An array of cell values in the current row
        let userScore = ["N/A", "H", "M", "L"];

        if (rowNumber > 3) {
          let firstCellVal = rowData[1];
          let secondCellVal = rowData[2];
          let isUserName = userScore.some((pref) =>
            secondCellVal.includes(pref)
          );

          if (firstCellVal === "Username") {
            row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "EEEEEE" },
                bgColor: { argb: "EEEEEE" },
              };
              cell.font = {
                name: "Calibri",
                size: 12,
                color: { argb: "4390DE" },
                bold: true,
              };
              if (colNumber > 2) {
                cell.merge(row.getCell(2));
              }
            });
            row.height = 25;
          } else if (!isUserName && firstCellVal != "Username") {
            row.eachCell({ includeEmpty: true }, (cell) => {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFFC2" },
                bgColor: { argb: "FFFFC2" },
              };
              cell.font = {
                name: "Calibri",
                size: 13,
                color: { argb: "424242" },
                bold: true,
              };
              cell.alignment = {
                vertical: "middle",
                horizontal: "center",
                wrapText: true,
              };
            });
            row.height = 30;
          } else {
            row.height = 25;
          }
        }
      });
      //....................................

      let startColumnNumber = 2;
      let endColumnNumber = 1;
      // merge column for headers....
      for (let i of this.topics) {
        const mergeRow = 1; // Specify the row number to merge cells
        endColumnNumber += i.subtopic_count;

        worksheet2.mergeCells(
          mergeRow,
          startColumnNumber,
          mergeRow,
          endColumnNumber
        );
        startColumnNumber = endColumnNumber + 1;
      }

      startColumnNumber = 2;
      endColumnNumber = 1;
      for (let j of this.sub_topics) {
        const mergeRow = 2;
        endColumnNumber += j.course_count;
        if (j.course_count > 1) {
          worksheet2.mergeCells(
            mergeRow,
            startColumnNumber,
            mergeRow,
            endColumnNumber
          );
        }
        startColumnNumber = endColumnNumber + 1;
      }

      worksheet2.getRows(1, worksheet2.actualRowCount).forEach((x) =>
        x.eachCell((cell) => {
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
          cell.alignment = { vertical: "middle", horizontal: "center" };
        })
      );

      //set row height for default rows..
      worksheet2.getRow(1).height = 25;
      worksheet2.getRow(2).height = 25;
      worksheet2.getRow(3).height = 170;

      worksheet2.lastRow.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
      });

      // rotate text in courses row
      worksheet2.getRow(3).eachCell((cell, colIndex) => {
        if (colIndex >= 2) {
          cell.alignment = {
            textRotation: 90,
            horizontal: "center",
            wrapText: true,
          };
        }
      });

      worksheet2.columns.forEach((column, i) => {
        column.width = i == 0 ? 40 : 30; //headers[i].length + 5
      });

      workbook2.xlsx.writeBuffer().then((val) => {
        let blob = new Blob([val], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, fileName);
      });
    },

    async SaveEvaluate() {
      let self = this;
      const userdata = JSON.parse(
        localStorage.getItem("vivek_authenticated_user_data")
      );
      self.crudloading = true;
      const memberid = userdata.memberId;

      let request = {
        courseId: self.evaluateData.courseId,
        emailLanguage: self.evaluateData.e_emailLanguage,
        userAssignmentId: self.evaluateData.userAssignmentId,
        departmentAssignmentId: self.evaluateData.departmentAssignmentId,
        companyId: localStorage.getItem("companyID"),
        assignmentId: self.evaluateData.assignmentId,
        mainTopicId: self.evaluateData.mainTopicId,
        subTopicId: self.evaluateData.subTopicId,
        course: self.evaluateData.course,
        choice: self.activeTab != 0 ? self.choices[self.activeTab] : "N/A", //self.activeTab == 1 ? "H" : self.activeTab == 2 ? "M" : "L", //self.evaluateData.choice,,
        choiceSelectNo: self.activeTab != 0 ? self.activeTab : null, //self.evaluateData.choiceSelectNo,
        idOfCourse: self.evaluateData.idOfCourse,
        flagCourseSelect: true,
        courseEmailFlag: self.evaluateData.e_flagCourseSelect,
        preQuizEmailFlag: self.evaluateData.e_preQuizEmailFlag,
        postQuizEmailFlag: self.evaluateData.e_postQuizEmailFlag,
        createOrUpdateBy: memberid,
      };
     console.log("emaildata",request);
      axios
        .post(`${self.web_url}ISO/SaveEvaluate`, request)
        .then(function (response) {
          if (response.data.status == 0) {
            alert("Sussefully save evaluate.");
            self.showDataPage = false;
            self.crudloading = true;
            self.evaluateData = {};
            self.evaluateDialog = false;
            self.usersOfDeptList = [];
            self.arrangeResult = [];
            self.courseEmailFlag = false;
            self.preQuizEmailFlag = false;
            self.postQuizEmailFlag = false;
            self.getISOAssignment();
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async getPreviousDepartment() {
      let self = this;
      axios
        .post(`${self.web_url}ISO/GetISOAssignmentDepartment_V3`, {
          companyId: localStorage.getItem("companyID"),
        })
        .then(function (response) {
          console.log("emaildataresponse",response.data.data);
          if (response.data.status == 0) {
            if (response.data.data) {
              let iso_deptlist = response.data.data.departmentList || [];

              const subTopicsMap = new Map(
                self.sub_topics.map((s) => [s.subTopicId, s])
              );

              for (let u in iso_deptlist) {
                let courseTemp = iso_deptlist[u].isoCourseList.map((l) => ({
                  ...l,
                  flagCourseSelect: l.flagCourseSelect, //false,
                  CourseIndex: l.courseId + self.usersOfDeptList.length,
                  course_count: subTopicsMap.get(l.subTopicId)?.course_count,
                }));

                const tempItem = {
                  departmentName: iso_deptlist[u].departmentName,
                  departmentId: iso_deptlist[u].departmentId,
                  departmentNo: self.usersOfDeptList.length + 1,
                  departmentUserAssignmentId:
                    iso_deptlist[u].departmentUserAssignmentId,
                  departmentAssignmentId:
                    iso_deptlist[u].departmentAssignmentId,
                  companyId: localStorage.getItem("companyID"),
                  assignmentId: self.assignmentId, //iso_deptlist[u].assignmentId,
                  Courses: courseTemp,
                  courseUI: self.arrangeResult,
                  user: iso_deptlist[u].user.map((y) => ({
                    ...y,
                    showPercentage: y.isoCourseList.some(
                      (j) => j.flagCourseSelect
                    ),
                  })),
                };

                self.usersOfDeptList.push(tempItem);
              }

              self.hasDeptData = self.usersOfDeptList !== 0;
              self.showDataPage = self.topics.length !== 0;
              self.crudloading = false;
            } else {
              self.hasDeptData = false;
              self.showDataPage = self.topics.length !== 0;
              self.crudloading = false;
            }
          } else {
            self.crudloading = false;
            alert("Something went wrong.");
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
    async getISOAssignment() {
      let self = this;
      self.crudloading = true;

      axios
        .post(`${self.web_url}ISO/GetISOAssignmentDepartment`, {
          companyId: localStorage.getItem("companyID"),
        })
        .then(function (res) {
          console.log("res.data.data",res.data.data);
          let check_maintopic = res.data.data.mainTopicList?.length > 0;
          if (check_maintopic) {
            self.topics = res.data.data.mainTopicList;
            self.sub_topics = res.data.data.subTopicList;
            self.courses_list = res.data.data.courseList.map((cou) => ({
              ...cou,
              courseLength: cou.course?.length,
            }));
            self.assignmentId = res.data.data.assignmentId;
            self.getPreviousDepartment();
          } else {
            //to show example data for setting section null condition

            self.hasDeptData = false;
            self.showDataPage = self.topics.length !== 0; // ? true : false;
            self.crudloading = false;
            self.noDataDialog = true;
            return;
          }

          let topic_t = [];
          //for sub topic width control
          for (let t1 in self.topics) {
            const mainTopicId = self.topics[t1].mainTopicId;
            const temp_item = self.sub_topics.filter(
              (n) => n.mainTopicId === mainTopicId
            );
            const newItem = {
              ...self.topics[t1],
              textMainLength: self.topics[t1].mainTopic.length,
              subtopic_count: temp_item.length,
            };
            topic_t.push(newItem);
          }
          self.topics = topic_t;

          let subtopic_t = [];
          for (let c in self.sub_topics) {
            const subTopicId = self.sub_topics[c].subTopicId;
            const coursesWithSameSubTopicId = self.courses_list.filter(
              (s) => s.subTopicId === subTopicId
            );
            const newItem = {
              ...self.sub_topics[c],
              textSubLength: self.sub_topics[c].subTopic.length,
              course_count: coursesWithSameSubTopicId.length,
              textSubHeight: self.setHeight(
                self.sub_topics[c].subTopic.length,
                coursesWithSameSubTopicId.length
              ),
            };
            subtopic_t.push(newItem);
          }
          self.sub_topics = subtopic_t;
          self.controlHeightOfSubTopic();
          self.arrangeCourseList();
        })
        .catch(function (err) {
          throw err;
        });
    },
    setHeight(length, count) {
      let temp_h = 0;

      if (length > 50) {
        temp_h = count > 3 ? 0 : 75;
      } else if (length > 35) {
        temp_h = count > 3 ? 0 : 67;
      } else {
        temp_h = count > 3 ? 0 : 50;
      }
      return temp_h;
    },
    controlHeightOfSubTopic() {
      let self = this;
      let heightTemp = self.sub_topics.reduce(
        (max, obj) => (obj.textSubHeight > max.textSubHeight ? obj : max),
        self.sub_topics[0]
      );
      self.subTopicHeight = heightTemp.textSubHeight + "px";
    },
    WhereSubTopic(Id) {
      return this.sub_topics.filter((x) => x.mainTopicId == Id);
    },
    WhereCoursesOfSubTopic(subId) {
      return this.courses_list.filter((x) => x.subTopicId == subId);
    },
    changeTab(tabNumber) {
      this.activeTab = tabNumber;
      this.evaluateData.choiceSelectNo = tabNumber;
      this.evaluateData.choice = this.choices[tabNumber];
    },
    async clickEvaluate(u_id, u_name, c_item, dept_info) {
      let self = this;
  console.log("c-item",c_item);
  console.log("c-naime",u_name);
  console.log("c-naime",u_id);
      let checkPermission = self.check_Permissions("trainingroadmapiso");
      if (!checkPermission) {
        self.permissionDialog = true;
        self.permissionMessage =
          "You don't have permission to access Evaluation.";
      } else {
        let request = {
          companyId: localStorage.getItem("companyID"),
          userID: u_id,
          courseID: c_item.idOfCourse,
          offset: new Date().getTimezoneOffset(),
        };

        axios
          .post(
            `${self.web_url}Assignment/GetAssignmentEvaluateReport`,
            request
          )

          .then(function (response) {
            console.log("emaildata",response.data);
            if (response.data.status == 0) {
              self.evaluateData = {
                e_fullName: u_name,
                e_emailLanguage:c_item.emailLanguage,
                e_flagCourseSelect:c_item.flagCourseSelect,
                e_postQuizEmailFlag:c_item.postQuizEmailFlag,
                e_preQuizEmailFlag:c_item.preQuizEmailFlag,
                e_deptName: dept_info.departmentName,
                e_mainTopic: self.topics.find(
                  (m) => m.mainTopicId == c_item.mainTopicId
                )
                  ? self.topics.find((m) => m.mainTopicId == c_item.mainTopicId)
                      .mainTopic
                  : "",
                e_subTopic: self.sub_topics.find(
                  (s) => s.subTopicId == c_item.subTopicId
                )
                  ? self.sub_topics.find(
                      (s) => s.subTopicId == c_item.subTopicId
                    ).subTopic
                  : "",
                e_course: c_item.course,
                e_coursePeriod: response.data.data[0].coursePeriod, //26/06/2023-26/06/2023
                courseId: c_item.courseId,
                userAssignmentId: c_item.userAssignmentId,
                departmentAssignmentId: dept_info.departmentAssignmentId,
                assignmentId: dept_info.assignmentId,
                mainTopicId: c_item.mainTopicId,
                subTopicId: c_item.subTopicId,
                e_courseStatus: c_item.courseStatus,
                course: c_item.course,
                choice: c_item.choice,
                choiceSelectNo: c_item.choiceSelectNo,
                idOfCourse: c_item.idOfCourse,
              };
              console.log("emaildatadata",self.evaluateData);
              self.e_detailCourseList = response.data.data;
              self.e_detailQuizList = response.data.data1;
              console.log("emaildatadata1",self.e_detailCourseList );
              console.log("emaildatadata2",self.e_detailQuizList);
              self.activeTab =
                self.evaluateData.choiceSelectNo != null
                  ? self.evaluateData.choiceSelectNo
                  : 0;

              self.evaluateDialog = true;
              self.quizstatus = self.e_detailQuizList[0].statusQuiz;
            }
          })
          .catch(function (err) {
            throw err;
          });
      }
    },
  },
};
</script>

<style scoped>
.bold-label {
  font-weight: bold;
}
.custom-main-table {
  border-collapse: collapse !important;
  width: 100%;
  border: 1px solid #a6cc39;
  border-radius: 8px 0px 0px 0px;
  word-break: break-all;
}

.custom-main-table th {
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.custom-sub-table {
  border-collapse: collapse !important;
  width: 100%;
}
.custom-course-table {
  border-collapse: collapse !important;
  width: 100%;
  padding: 0px !important;
}
.custom-course-table td {
  padding: 0px !important;
}
.main-topic-cell {
  background-color: rgb(103, 171, 48, 0.15);
  color: #707070;
  text-align: center;
  border: 1px solid #a6cc39;
  width: 99px;
}
.sub-topic-cell-bg {
  background-color: rgb(103, 171, 48, 0.29);
  color: #707070;
  text-align: center;
  vertical-align: middle;
  border-right: 1px solid #a6cc39;
  width: 99px;
}
.sub-topic-cell {
  background-color: #fff;
  padding: 0px !important;
}
.sub-topic-cell td {
  text-align: center !important;
  vertical-align: bottom;
  min-width: 99px;
  height: 250px;
  word-break: break-all;
}
.course-varticle {
  height: 249px;
  border-left: 1px solid #a6cc39;
}
.rotated-text {
  color: #67ab30;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  text-align: start;
  height: 250px;
  overflow: hidden;
  word-break: break-all;
}

.course-container {
  color: #67ab30;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  text-align: start;
  height: 250px;
  overflow: hidden;
  word-break: break-all;
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Department table css */
.dept-large-column {
  color: #424242;
  width: 224px !important;
  min-width: 224px !important;
  height: 55px;
  border: 1px solid rgb(112, 112, 112, 0.7);
  font-weight: normal;
  background-color: rgb(255, 201, 0, 0.05);
}
.dept-col-cell {
  background-color: rgb(255, 201, 0, 0.05);
  text-align: center !important;
  justify-content: center;
  align-items: center;
  height: 54px;
  border: 1px solid rgb(112, 112, 112, 0.7);
}
.custom-dept-table {
  border-collapse: collapse !important;
  width: 100%;
  border-top: 1px solid rgb(112, 112, 112, 0.7);
  border-bottom: 1px solid rgb(112, 112, 112, 0.7);
  border-radius: 8px 0px 0px 0px;
}
.dept-user-col-large {
  height: 43px;
  vertical-align: middle;
  text-align: center;
  border: 1px solid rgb(112, 112, 112, 0.7);
  background-color: rgb(204, 204, 204, 0.27);
}
.dept-userEmpty-col {
  height: 43.5px !important;
  min-width: 101.5px;
  width: 99.6px;
  text-align: center;
  background-color: rgb(204, 204, 204, 0.27);
}
.dept-username-col-large {
  width: 234px;
  height: 45px;
  vertical-align: middle;
  border: 1px solid rgb(112, 112, 112, 0.7);
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.username-custom {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-word;
  padding-left: 2px;
  width: 224px;
  color: #424242;
}
.dept-user-col {
  vertical-align: middle;
  text-align: center;
  border: 1px solid rgb(112, 112, 112, 0.7);
  background-color: #ffffff;
  height: 45px;
  min-width: 99.5px;
}
</style>

<style scoped>
.tableBtnSC.v-btn:not(.v-btn--round).v-size--small {
  font-size: 12px;
  height: 26px;
  min-width: 30px;
  border-radius: 8px !important;
  padding: 0 5px;
}
.TRtopicsavebtn {
  font-size: 18px;
  border-radius: 8px;
}
.assessmentFont {
  font-size: 16px;
  color: #424242;
}
.btnfont-style {
  font-size: 16px;
  border-radius: 8px;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  font-size: 17px !important;
  color: #47484b !important;
  background-color: #e0e0e0 !important;
  opacity: 1;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td {
  font-size: 16px !important;
  color: #47484b !important;
  opacity: 1;
  vertical-align: center;
}
::v-deep .v-data-table thead th:first-child {
  border-radius: 8px 0 0 8px;
} /**.child_table */
::v-deep .v-data-table thead th:last-child {
  border-radius: 0 8px 8px 0;
}
::v-deep .v-dialog--scrollable,
.v-dialog--scrollable > form {
  border-radius: 16px !important;
}
</style>

<style scoped>
::v-deep
  .sc-checkbox.v-input--hide-details
  > .v-input__control
  > .v-input__slot {
  margin-bottom: 5px;
  justify-content: center !important;
}
</style>

<style scoped>
::v-deep .evaluate-chart-style {
  background: rgb(187, 187, 187, 0.35);
  width: 366px !important;
  height: 150px;
}
.statuscolor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 25px;
  border-radius: 5px;
}
.tab-container {
  display: flex;
  align-items: center;
}

.score-btn {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 3px solid #707070;
  background-color: white;
  cursor: pointer;
}

.score-btn.active {
  background-color: #a6cc39;
}

.tab-line {
  width: 120px;
  height: 3px;
  background-color: #707070;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  margin-top: 1.9rem !important;
}
.tab-title {
  font-size: 13px;
  width: 38px;
  height: 24px;
  margin-bottom: 7px;
  border-radius: 8px;
  text-align: center;
}
.tab {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.criteria-title {
  font-size: 13px;
  border-radius: 8px !important;
  padding: 4px 10px;
}
.percent-title {
  font-size: 16px;
  border-radius: 0ch !important;
  padding: 3px 12px;
}
.percent-user {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  width: 45px;
  height: 22px;
  border-radius: 1px !important;
}
.userlengths {
  font-size: 16px;
  width: 170px;
  border-radius: 1px !important;
  padding: 15px 4px 0 4px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
</style>

<style scoped>
.section-column {
  color: #707070;
  font-size: 24px;
  vertical-align: middle;
  min-width: 226px;
  background-color: rgb(166, 204, 57, 0.55);
  border: 1px solid #a6cc39;
}
.finished {
  color: #4fb14e !important;
  background: rgba(111, 220, 66, 0.1) !important;
}
.notstarted {
  color: #ff6060 !important;
  background: rgba(255, 96, 96, 0.1) !important;
}
.notfinished {
  color: #424242 !important;
  background: rgba(66, 66, 66, 0.1) !important;
}
.before {
  color: #4390df !important;
  background: rgba(67, 144, 223, 0.1) !important;
}
.afterexpired {
  color: #ffb100 !important;
  background: rgba(255, 177, 0, 0.1) !important;
}
::v-deep .table-wrapper {
  overflow-x: auto;
  scrollbar-width: 0px !important; /* For Firefox */
  scrollbar-color: #888 #f5f5f5; /* For Chrome, Edge, and Safari */
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: #88888859; /*#888;*/
  border-radius: 5px;
  height: 7px;
}
.table-wrapper::-webkit-scrollbar {
  height: 9px;
}

.table-wrapper::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 4px;
}

.noquiz {
  position: relative;
  top: -25%;
  text-align: center;
  font-size: 18px;
  color: #424242;
}
</style>
<style scoped>
::v-deep .subname-custom {
  overflow: hidden;
  width: 99px;
}
.webkit-line-clamp-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
